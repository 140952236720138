import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import userReducer from "./user/userSlice";
import notificationReducer from "./notifications/notificationSlice";
import versionReducer from "./version/versionSlice";
import accountingReducer from "./accounting/accountingSlice";
import runsReducer from "./run/runSlice";
import distributionReducer from "./distribution/distributionSlice";
import fundReducer from "./fund/fundSlice";
import excelModelReducer from "./excelModel/excelModelSlice";

export const store = configureStore({
  reducer: {
    notifications: notificationReducer,
    user: userReducer,
    version: versionReducer,
    accounting: accountingReducer,
    runs: runsReducer,
    distributions: distributionReducer,
    funds: fundReducer,
    excelModel: excelModelReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
