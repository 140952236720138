import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  addDistribution,
  updateDistribution,
} from "../../store/distribution/distributionFunction";
import { Distribution } from "../../models/distribution";
import _ from "lodash";
import ProgressButton from "../ProgressButton/ProgressButton";
import CheckboxControl from "../CheckboxControl/CheckboxControl";

type ModalProps = {
  open: boolean;
  handleClose: () => void;
  distribution: Distribution;
};

// Define Zod schema for form validation
const schema = z.object({
  fundId: z.number().int().positive("Fund is required"),
  classType: z.string().min(6, "Class required"),
  salutation: z
    .string()
    .min(6, "Salutation must be at least 6 characters long"),
  email: z.string().email("Invalid email").min(1, "Email is required"),
  internal: z.boolean(),
});

// DistributionModal component
// Create Mode: When no distribution is used as props to the component
// Edit Mode: When a distribution is passed as props to the component
export default function DistributionEditModal({
  open,
  handleClose,
  distribution,
}: ModalProps) {
  const [initialDistribution, setInitialDistribution] =
    React.useState<Distribution | null>();
  const [attemptedEdit, setAttemptedEdit] = React.useState(false);
  const dispatch = useAppDispatch();
  const funds = useAppSelector((state) => state.funds).funds;
  const isSaving = useAppSelector((state) => state.distributions.loading);
  const isEditMode = distribution && !!distribution.id;
  const titleText = `${isEditMode ? "Edit" : "Add New"} Recipient`;
  const dialogContentText = `${isEditMode ? "To edit the" : "To add a new"} recipient, please fill out the form below.`;
  const saveText = `${isEditMode ? "Edit" : "Add"}`;
  const buttonIcon = isEditMode ? EditIcon : AddIcon;

  useEffect(() => {
    if (open) {
      setInitialDistribution(distribution);
      setAttemptedEdit(false);
      reset({
        fundId: distribution?.fundId ?? 0,
        classType: distribution?.class ?? "Wholesale",
        salutation: distribution?.salutation ?? "",
        email: distribution?.email ?? "",
        internal: distribution?.internal ?? false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, distribution]);

  type FormData = z.infer<typeof schema>;
  const { control, handleSubmit, reset, watch } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      fundId: distribution?.fundId ?? 0,
      classType: distribution?.class ?? "Wholesale",
      salutation: distribution?.salutation ?? "",
      email: distribution?.email ?? "",
      internal: distribution?.internal ?? false,
    },
  });

  const formValues = watch();

  const hasUserEditedForm = (data: FormData) => {
    return (
      !_.isEqual(data.classType, initialDistribution?.class) ||
      !_.isEqual(data.salutation, initialDistribution?.salutation) ||
      !_.isEqual(data.email, initialDistribution?.email) ||
      !_.isEqual(data.internal, initialDistribution?.internal) ||
      !_.isEqual(data.fundId, initialDistribution?.fundId)
    );
  };

  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { width: 500 } }}>
      <DialogTitle>{titleText}</DialogTitle>
      <form
        onSubmit={handleSubmit(async (data) => {
          if (isEditMode) {
            setAttemptedEdit(true);
            if (hasUserEditedForm(data)) {
              await dispatch(
                updateDistribution({ id: distribution.id, ...data }),
              );
              handleClose();
            }
          } else {
            await dispatch(addDistribution(data));
            handleClose();
          }
        })}
      >
        <DialogContent>
          <DialogContentText>{dialogContentText}</DialogContentText>
          <Grid container spacing={2} alignItems="center" sx={{ pt: 3 }}>
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Fund
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <div style={{ height: "35px" }}>
                <Controller
                  name="fundId"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ minWidth: "120px" }}
                    >
                      <Select
                        labelId="funds-select-label"
                        id="funds-select"
                        label="Fund"
                        displayEmpty
                        value={value} // Set the value prop to selectedFund
                        onChange={onChange} // Set the onChange prop to handleSelectedFundChange
                        inputProps={{ "aria-label": "Without label" }}
                        disabled={isEditMode}
                        sx={{
                          backgroundColor: "#FFFFFF",
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          border: "1px solid",
                          borderColor: error ? "red" : "#CBCBCB",
                          height: "34px",
                        }}
                      >
                        <MenuItem value={0}>Select Fund</MenuItem>
                        {funds.map((fund) => (
                          <MenuItem key={fund.fundId} value={fund.fundId}>
                            {fund.fund.code}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        sx={{
                          color: "error.main",
                        }}
                      >
                        {error?.message ?? ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Class
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <div style={{ height: "35px" }}>
                <Controller
                  name="classType"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ minWidth: "120px" }}
                    >
                      <Select
                        labelId="class-select-label"
                        id="class-select"
                        label="Class"
                        value={value} // Set the value prop to selectedFund
                        onChange={onChange} // Set the onChange prop to handleSelectedFundChange
                        inputProps={{ "aria-label": "Without label" }}
                        disabled={isEditMode}
                        sx={{
                          backgroundColor: "#FFFFFF",
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          border: "1px solid",
                          borderColor: error ? "red" : "#CBCBCB",
                          height: "34px",
                        }}
                      >
                        <MenuItem key={"Wholesale"} value={"Wholesale"}>
                          Wholesale
                        </MenuItem>
                      </Select>
                      <FormHelperText
                        sx={{
                          color: "error.main",
                        }}
                      >
                        {error?.message ?? ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Internal Only
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <div style={{ height: "35px" }}>
                <CheckboxControl name="internal" control={control} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Salutation
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <div style={{ height: "35px" }}>
                <Controller
                  name="salutation"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur, ref },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth>
                      <TextField
                        name="salutation"
                        size="small"
                        placeholder="salutation"
                        required
                        inputRef={ref}
                        value={value}
                        InputLabelProps={{ shrink: false }}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={Boolean(error)}
                      />
                      <FormHelperText
                        sx={{
                          color: "error.main",
                          width: "250px",
                        }}
                      >
                        {error?.message ?? ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Email
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <div style={{ height: "35px" }}>
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur, ref },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth>
                      <TextField
                        name="email"
                        size="small"
                        placeholder="example@mail.com"
                        required
                        inputRef={ref}
                        value={value}
                        InputLabelProps={{ shrink: false }}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={Boolean(error)}
                      />
                      <FormHelperText
                        sx={{
                          color: "error.main",
                        }}
                      >
                        {error?.message ?? ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {attemptedEdit && !hasUserEditedForm(formValues) && (
            <Typography color="error">
              Please modify a value before submitting.
            </Typography>
          )}
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton
            inProgress={isSaving}
            text={saveText}
            Icon={buttonIcon}
            color="#ffffff"
            backgroundColor="#2040cd"
          />
        </DialogActions>
      </form>
    </Dialog>
  );
}
