import { Box } from "@mui/material";

function Logo() {
  return (
    <Box
      component="img"
      sx={{
        display: "inline-block",
        height: 45,
      }}
      src="/static/logo_white.png"
      alt="Metrics_Logo"
    />
  );
}

export default Logo;
