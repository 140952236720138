import { Container, Typography } from "@mui/material";

interface PageTitleProps {
  title: string;
}

export default function PageTitle({ title }: PageTitleProps) {
  return (
    <Container maxWidth={false}>
      <Typography
        variant="h5"
        paragraph
        align="left"
        sx={{ paddingTop: "16px" }}
      >
        {title}
      </Typography>
    </Container>
  );
}
