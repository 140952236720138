import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";

interface UnitPriceDataGridProps {
  columns: GridColDef[];
  rows: GridRowsProp;
}

const StyledDataGrid = styled(DataGrid)({
  backgroundColor: "#ffffff",
  border: "1px solid",
  borderColor: "#eeeeee",
  paddingBottom: "0",
  paddingLeft: "8px",
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
});

export default function UnitPriceDataGrid({
  columns,
  rows,
}: UnitPriceDataGridProps) {
  return (
    <Box style={{ flex: 1 }}>
      <StyledDataGrid
        autoPageSize
        disableSelectionOnClick
        columns={columns}
        rows={rows}
      />
    </Box>
  );
}
