import { useState } from "react";
import { Outlet } from "react-router-dom";
// Components
import { AuthenticatedTopBar, DashboardSidebar } from "./components";
// @MUI
import { Box, styled } from "@mui/material";

const RootStyle = styled("div")({
  display: "flex",
  flexDirection: "column",
  maxHeight: "100vh",
  overflow: "none",
});

const MainStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  position: "relative",
  height: "100vh",
  padding: theme.spacing(1),
}));

const BackGroundStyle = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflowY: "auto",
  opacity: 1.0,
  repeat: "none",
  backgroundColor: "white",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom center",
  backgroundSize: "100% auto",
  zIndex: -1,
}));

// Dashboard UI Layout Wrapper for Authenticated user
function Dashboard({ access }: { access: boolean }) {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <>
      <RootStyle>
        <AuthenticatedTopBar />
        {access && (
          <DashboardSidebar open={open} onOpen={() => setOpen(!open)} />
        )}
        <BackGroundStyle />
        <MainStyle
          sx={{
            ml: access ? (open ? "205px" : "64px") : "0px",
          }}
        >
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}

export default Dashboard;
