import { Grid, GridProps } from "@mui/material";

interface FormRowGridItemProps extends GridProps {
  children: React.ReactNode;
}

function FormRowGridItem({ children, ...props }: FormRowGridItemProps) {
  return (
    <Grid item xs={6} {...props}>
      {children}
    </Grid>
  );
}

export default FormRowGridItem;
