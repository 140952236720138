import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getFund, getFunds, updateFundAssumptions } from "./fundFunction";
import { FundDetails } from "../../models/fundDetails";

const initialState: {
  funds: FundDetails[];
  isLoadingFunds: boolean;
  selectedFund: FundDetails;
  isLoadingSelectedFund: boolean;
  isUpdatingFund: boolean;
} = {
  funds: [],
  isLoadingFunds: false,
  selectedFund: {} as FundDetails,
  isLoadingSelectedFund: false,
  isUpdatingFund: false,
};

export const fundSlice = createSlice({
  name: "funds",
  initialState,
  reducers: {
    clearFunds: (state) => {
      state.funds = [];
      state.selectedFund = {} as FundDetails;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFunds.pending, (state) => {
        state.funds = [];
        state.isLoadingFunds = true;
      })
      .addCase(
        getFunds.fulfilled,
        (state, action: PayloadAction<FundDetails[]>) => {
          state.funds = action.payload;
          state.isLoadingFunds = false;
        },
      )
      .addCase(getFund.pending, (state) => {
        state.selectedFund = {} as FundDetails;
        state.isLoadingSelectedFund = true;
      })
      .addCase(
        getFund.fulfilled,
        (state, action: PayloadAction<FundDetails>) => {
          state.selectedFund = action.payload;
          state.isLoadingSelectedFund = false;
        },
      )
      .addCase(updateFundAssumptions.pending, (state) => {
        state.isUpdatingFund = true;
      })
      .addCase(
        updateFundAssumptions.fulfilled,
        (state, action: PayloadAction<FundDetails>) => {
          state.selectedFund = action.payload;
          state.isUpdatingFund = false;
          // find the index of the fund that has the same id as the updated fund
          const index = state.funds.findIndex(
            (fund) => fund.id === action.payload.fundId,
          );

          // update the fund in the funds array
          if (index !== -1) {
            state.funds[index].lastModifiedBy = action.payload.lastModifiedBy;
            state.funds[index].lastModifiedTimestamp =
              action.payload.lastModifiedTimestamp;
          }
        },
      )
      .addCase(updateFundAssumptions.rejected, (state) => {
        state.isUpdatingFund = false;
      });
  },
});

export const { clearFunds } = fundSlice.actions;

export default fundSlice.reducer;
