import { Controller } from "react-hook-form";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";

interface StringSelectControlProps {
  name: string;
  control: any;
  options: ReadonlyArray<string>;
}

function StringSelectControl({
  name,
  control,
  options,
}: StringSelectControlProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <FormControl
          fullWidth
          size="small"
          sx={{ minWidth: "120px", height: "35px" }}
        >
          <Select
            labelId={`${name}-select-label`}
            id={`${name}-select`}
            label="Fund"
            displayEmpty
            value={value}
            onChange={onChange}
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              backgroundColor: "#FFFFFF",
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              border: "1px solid",
              borderColor: error ? "red" : "#CBCBCB",
              height: "34px",
            }}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {error?.message ?? ""}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default StringSelectControl;
