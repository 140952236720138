import { Theme } from "@mui/system";

export default function DataGrid(theme: Theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-main": {
            backgroundColor: theme.palette.common.white,
            height: 1,
            borderRadius: "8px",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.common.white,
          },
          "& .MuiDataGrid-columnsContainer": {
            height: "40px",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            color: theme.palette.common.black,
            padding: 0,
          },
          "& .MuiDataGrid-virtualScroller": {
            overflowX: "visible",
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "white",
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            height: 30,
            minHeight: 15,
          },

          "& .MuiTablePagination-displayedRows": {
            color: "black",
          },

          "& .MuiTablePagination-root": {
            color: "#9ea9b4",
            padding: 0,
          },

          "& .MuiTablePagination-root .MuiIconButton-root": {
            color: "B3B2B2",
            backgroundColor: "transparent",
          },

          "& .MuiTablePagination-root .MuiIconButton-root :hover": {
            color: "#2040cd",
          },

          // '& .MuiSvgIcon-root': {
          //     color: theme.palette.common.white,
          // },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-sortIcon": {
            color: theme.palette.common.white,
          },
          "& .MuiDataGrid-filterIcon": {
            color: theme.palette.common.white,
          },
        },
      },
    },
    MuiDataGridCell: {
      root: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  };
}
