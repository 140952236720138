import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  addDistribution,
  deleteDistribution,
  getDistributions,
  updateDistribution,
} from "./distributionFunction";
import { Distribution } from "../../models/distribution";

const initialState: { distributions: Distribution[]; loading: boolean } = {
  distributions: [],
  loading: false,
};

export const distributionSlice = createSlice({
  name: "distributions",
  initialState,
  reducers: {
    clearDistributions: (state) => {
      state.distributions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDistributions.pending, (state) => {
        state.distributions = [];
        state.loading = true;
      })
      .addCase(
        getDistributions.fulfilled,
        (state, action: PayloadAction<Distribution[]>) => {
          state.distributions = action.payload;
          state.loading = false;
        },
      )
      .addCase(addDistribution.pending, (state) => {
        state.loading = true;
      })
      .addCase(addDistribution.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        addDistribution.fulfilled,
        (state, action: PayloadAction<Distribution>) => {
          state.distributions.push(action.payload);
          state.loading = false;
        },
      )
      .addCase(updateDistribution.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDistribution.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        updateDistribution.fulfilled,
        (state, action: PayloadAction<Distribution>) => {
          const index = state.distributions.findIndex(
            (d) => d.id === action.payload.id,
          );
          state.distributions[index] = action.payload;
          state.loading = false;
        },
      )
      .addCase(deleteDistribution.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDistribution.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        deleteDistribution.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.distributions = state.distributions.filter(
            (d) => d.id !== action.payload,
          );
          state.loading = false;
        },
      );
  },
});

export const { clearDistributions } = distributionSlice.actions;

export default distributionSlice.reducer;
