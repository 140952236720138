import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

const SearchFieldControl = styled(FormControl)({
  minWidth: "180px",
});

const SearchField = styled(TextField)({
  width: "180px",
  backgroundColor: "#ffffff",
  border: "1px solid",
  borderColor: "#eeeeee",
  borderRadius: "8px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

const SearchClearIcon = styled(IconButton)({
  padding: "0",
  marginRight: "-10px",
});

interface SearchControlProps {
  searchText: string;
  setSearchText: (value: string) => void;
}

export default function SearchControl({
  searchText,
  setSearchText,
}: SearchControlProps) {
  return (
    <SearchFieldControl>
      <SearchField
        size="small"
        id="outlined-basic"
        variant="outlined"
        placeholder="Search"
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
        InputLabelProps={{ shrink: false }}
        InputProps={{
          endAdornment: searchText && (
            <InputAdornment position="end">
              <SearchClearIcon
                edge="end"
                onClick={() => setSearchText("")}
                size="small"
              >
                <ClearIcon fontSize="small" />
              </SearchClearIcon>
            </InputAdornment>
          ),
        }}
      />
    </SearchFieldControl>
  );
}
