import { Controller } from "react-hook-form";
import {
  FormControl,
  TextField,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import React from "react";

interface TextFieldControlProps {
  name: string;
  control: any;
  placeholder: string;
  type?: string;
  endAdornment?: React.ReactNode;
  disabled?: boolean;
}

function TextFieldControl({
  name,
  control,
  placeholder,
  type = "number",
  endAdornment,
  disabled = false,
}: TextFieldControlProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <FormControl fullWidth>
          <TextField
            name={name}
            size="small"
            placeholder={placeholder}
            required
            type={type}
            inputRef={ref}
            value={value}
            InputLabelProps={{ shrink: false }}
            onChange={onChange}
            onBlur={onBlur}
            error={Boolean(error)}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ),
              inputProps: {
                style: {
                  MozAppearance: "textfield",
                },
              },
              sx: {
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
              },
            }}
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {error?.message ?? ""}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default TextFieldControl;
