import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, Checkbox } from "@mui/material";
import React from "react";

interface CheckboxControlProps {
  name: string;
  control: any;
  disabled?: boolean;
}

function CheckboxControl({
  name,
  control,
  disabled = false,
}: CheckboxControlProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControl size="small">
          <Checkbox
            name={name}
            size="small"
            checked={value}
            onChange={onChange}
            disabled={disabled}
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {error?.message ?? ""}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default CheckboxControl;
