import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import { ReactNode } from "react";

interface StyledAccordionSummaryProps {
  handleChange: (event: React.SyntheticEvent) => void;
  children?: ReactNode;
}

const StyledAccordionSummary = styled(
  ({ handleChange, children, ...props }: StyledAccordionSummaryProps) => (
    <AccordionSummary
      sx={{ pointerEvents: "none" }}
      expandIcon={
        <ExpandMoreIcon sx={{ pointerEvents: "auto" }} onClick={handleChange} />
      }
      {...props}
    >
      {children}
    </AccordionSummary>
  ),
)``;

export default StyledAccordionSummary;
