import { Box, styled } from "@mui/material";
import { useState } from "react";
import { Control } from "react-hook-form";
import { FundAssumptions } from "../../models/fundDetails";
import {
  CALCULATION_TYPES,
  FEE_TYPES,
  FEE_TYPE_MAP,
  RATE_TYPES,
} from "./EditFundSchema";
import ColumnItem from "./Components/ColumnItem/ColumnItem";
import FormRowGridContainer from "./Components/FormRowGridContainer/FormRowGridContainer";
import FormLabel from "./Components/FormLabel/FormLabel";
import MultiColumnContainer from "./Components/MultiColummContainer/MultiColumnContainer";
import AccordionLabel from "./Components/AccordionLabel/AccordionLabel";
import StyledAccordion from "./Components/StyledAccordion/StyledAccordion";
import StyledAccordionSummary from "./Components/StyledAccordionSummary/StyledAccordionSummary";
import StringSelectControl from "./Components/StringSelectControl/StringSelectControl";
import FormRowGridItem from "./Components/FormRowGridItem/FormRowGridItem";
import StyledAccordionDetails from "./Components/StyledAccordionDetails/StyledAccordionDetails";
import TextFieldControl from "../../components/TextFieldControl/TextFieldControl";
import DatePickerControl from "./Components/DatePickerControl/DatePickerControl";
import CheckboxControl from "../../components/CheckboxControl/CheckboxControl";

interface EditFundFormProps {
  control: Control<FundAssumptions>;
  formValues: FundAssumptions;
}

const FormBox = styled(Box)({
  overflowY: "auto",
  overflowX: "hidden",
  maxHeight: "calc(100vh - 222px)",
});

export default function EditFundForm({
  control,
  formValues,
}: EditFundFormProps) {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({
    nonPerfFeePanel: true,
    perfFeePanel: true,
    prevPeriodPanel: true,
  });

  const handleChange = (panel: string) => (event: React.SyntheticEvent) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: !prevExpanded[panel],
    }));
  };

  return (
    <FormBox>
      <StyledAccordion expanded={expanded["nonPerfFeePanel"]}>
        <StyledAccordionSummary handleChange={handleChange("nonPerfFeePanel")}>
          <AccordionLabel onClick={handleChange("nonPerfFeePanel")}>
            Non Performance Fees
          </AccordionLabel>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <MultiColumnContainer>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="Calculation Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.calculationType"}
                    control={control}
                    options={CALCULATION_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Management Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.managementFee"
                    control={control}
                    placeholder="value"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .managementFeeType as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Management Fee Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.managementFeeType"}
                    control={control}
                    options={FEE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Admin Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.adminFee"
                    control={control}
                    placeholder="value"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .adminFeeType as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Admin Fee Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.adminFeeType"}
                    control={control}
                    options={FEE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="IEE Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.ieeFee"
                    control={control}
                    placeholder="value"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .ieeFeeType as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="IEE Fee Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.ieeFeeType"}
                    control={control}
                    options={FEE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="RE Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.reFee"
                    control={control}
                    placeholder="value"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .reFeeType as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="RE Fee Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.reFeeType"}
                    control={control}
                    options={FEE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Registry Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.registryFee"
                    control={control}
                    placeholder="value"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .registryFeeType as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Registry Fee Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.registryFeeType"}
                    control={control}
                    options={FEE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="IMF Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.imfFee"
                    control={control}
                    placeholder="value"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .imfFeeType as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="IMF Fee Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.imfFeeType"}
                    control={control}
                    options={FEE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
          </MultiColumnContainer>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion expanded={expanded["perfFeePanel"]}>
        <StyledAccordionSummary handleChange={handleChange("perfFeePanel")}>
          <AccordionLabel onClick={handleChange("perfFeePanel")}>
            Performance Fees
          </AccordionLabel>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <MultiColumnContainer>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="Rate Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.performance.rateType"}
                    control={control}
                    options={RATE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel
                  label={`Margin Above ${formValues.fees.performance.rateType} Rate`}
                />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.performance.marginAboveRate"
                    control={control}
                    placeholder="value"
                    endAdornment={["%"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Allowance For RITC" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.performance.allowanceForRitc"
                    control={control}
                    placeholder="value"
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Performance Fee Applicable" />
                <FormRowGridItem>
                  <CheckboxControl
                    name="fees.performance.performanceFeeApplicable"
                    control={control}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label={`Return Above Hurdle`} />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.performance.returnAboveHurdle"
                    control={control}
                    placeholder="value"
                    endAdornment={["%"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Performance Fee Floor Hurdle" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.performance.performanceFeeFloorHurdle"
                    control={control}
                    placeholder="value"
                    endAdornment={["%"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Crystalised Performance Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.performance.crystalisedPerformanceFee"
                    control={control}
                    placeholder="value"
                    endAdornment={["$"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
          </MultiColumnContainer>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion expanded={expanded["prevPeriodPanel"]}>
        <StyledAccordionSummary handleChange={handleChange("prevPeriodPanel")}>
          <AccordionLabel onClick={handleChange("prevPeriodPanel")}>
            Previous Period Inputs
          </AccordionLabel>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <MultiColumnContainer>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="Closing Date" />
                <FormRowGridItem>
                  <DatePickerControl
                    name="previousPeriodInputs.closingDate"
                    control={control}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Application Units" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="previousPeriodInputs.applicationUnits"
                    control={control}
                    placeholder="value"
                    endAdornment={["#"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="NAV Unit Price" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="previousPeriodInputs.navUnitPrice"
                    control={control}
                    placeholder="value"
                    endAdornment={["$"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Period Under Performance" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="previousPeriodInputs.periodUnderPerformance"
                    control={control}
                    placeholder="value"
                    endAdornment={["$"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="Closing NAV" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="previousPeriodInputs.closingNav"
                    control={control}
                    placeholder="value"
                    endAdornment={["$"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Redemption Units" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="previousPeriodInputs.redemptionUnits"
                    control={control}
                    placeholder="value"
                    endAdornment={["#"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Cap On Performance Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="previousPeriodInputs.capOnPerformanceFee"
                    control={control}
                    placeholder="value"
                    endAdornment={["$"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
          </MultiColumnContainer>
        </StyledAccordionDetails>
      </StyledAccordion>
    </FormBox>
  );
}
