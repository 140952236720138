import { RowHeader } from "../InfoMultiTable/InfoMultiTable";

// Row headers for NAV Before Performance Fees section of report
export const navBeforePerfRowHeaders: Array<RowHeader> = [
  {
    label: "Model Date",
    key: "unitPriceDataModel.modelTiming.runDate",
  },
  {
    label: "NAV Before Performance Fees",
    type: "money",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Prior Day NAV Before Performance Fees + Capital Flows",
    type: "money",
    key: "unitPriceModelOutputs.priorDayNavBeforePerfAndCapFlows",
  },
  {
    label: "Daily Profit and Loss",
    type: "money",
    key: "unitPriceDataModel.fundFinancials.grossProfit",
  },
  {
    label: "Distributions Declared (Prior Day)",
    type: "money",
    key: "unitPriceDataModel.unitsAllocations.distributionsPayable",
  },
  {
    label: "Adjustments to Gross Profit",
    type: "money",
    key: "unitPriceDataModel.fundFinancials.adjustmentsToGrossProfit",
  },
  {
    label: "Adjustments to NAV Before Fees",
    type: "money",
    key: "unitPriceDataModel.fundFinancials.adjustmentToNavBeforeFee",
  },
  {
    label: "Total used to calculate expenses (except RE fee)",
    type: "money",
    key: "unitPriceModelOutputs.totalUsedToCalcExpenses",
  },
  {
    label: "Class Specific Expenses",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Daily Management Fee",
    type: "money",
    key: "unitPriceModelOutputs.dailyManagementFee",
  },
  {
    label: "Daily RE Fee",
    type: "money",
    key: "unitPriceModelOutputs.dailyReFee",
  },
  {
    label: "Daily Fund Accounting & Admin Fee",
    type: "money",
    key: "unitPriceModelOutputs.dailyAdminFee",
  },
  {
    label: "Daily Registry Fee",
    type: "money",
    key: "unitPriceModelOutputs.dailyRegistryFee",
  },
  {
    label: "Daily IEE Fee",
    type: "money",
    key: "unitPriceModelOutputs.dailyIeeFee",
  },
  {
    label: "Daily IMF Fee",
    type: "money",
    key: "unitPriceModelOutputs.dailyImfFee",
  },
  {
    label: "Total Class Specific Expenses",
    type: "money",
    key: "unitPriceModelOutputs.totalClassSpecificExpenses",
    sx: { pb: 3, borderTop: 1 },
  },
  {
    label: "Crystallised Prior Accrual Period Performance Fee",
    type: "money",
    key: "unitPriceModelOutputs.crystallisedPerformanceFee",
    sx: { fontWeight: "bold" },
  },
  {
    label: "NAV Before Performance Fees ",
    type: "money",
    key: "unitPriceModelOutputs.navBeforePerfFees",
    sx: { fontWeight: "bold" },
  },
  {
    label: "CUM NAV Unit Price Before Performance Fees",
    key: "unitPriceModelOutputs.unitPriceBeforePerfFees",
    sx: { fontWeight: "bold", borderTop: 1, borderBottom: 1 },
  },
];

// Row headers for NAV Post Performance Fees section of report
export const navPostPerfRowHeaders: Array<RowHeader> = [
  {
    label: "Model Date",
    key: "unitPriceDataModel.modelTiming.runDate",
  },
  {
    label: "Unit Price Before Performance Fees",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "CUM NAV Unit Price Before PF",
    key: "unitPriceModelOutputs.unitPriceBeforePerfFees",
  },
  {
    label: "Hurdle Performance",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Rate Used",
    key: "unitPriceDataModel.fees.performance.rateType",
  },
  {
    label: "Margin",
    type: "percent",
    key: "unitPriceDataModel.fees.performance.marginAboveRate",
  },
  {
    label: "Hurdle Rate",
    type: "percent",
    key: "unitPriceModelOutputs.hurdleRate",
  },
  {
    label: "Applied Days in Year",
    key: "unitPriceDataModel.modelTiming.daysInYear",
  },
  {
    label: "Daily Hurdle Rate",
    type: "percent",
    key: "unitPriceModelOutputs.dailyHurdleRate",
    sx: { pb: 3 },
  },
  {
    label: "Sum Monthly Distributions (as of yesterday)",
    key: "unitPriceModelOutputs.sumMonthlyDistributionsPriorDay",
  },
  {
    label: "Unit Price - Period Start incl Adjustments for Distributions",
    type: "decimal",
    key: "unitPriceModelOutputs.unitPricePeriodStartAdj",
  },
  {
    label: "Return Per Unit - Hurdle",
    type: "percent",
    key: "unitPriceModelOutputs.returnPerUnitHurdle",
    sx: { borderTop: 1 },
  },
  {
    label: "Fund Performance",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Unit Price - Period Start incl Adjustments for Distributions",
    key: "unitPriceDataModel.previousPeriodInputs.navUnitPrice",
  },
  {
    label: "Return Per Unit - Fund",
    type: "percent",
    key: "unitPriceModelOutputs.returnPerUnitFund",
    sx: { borderTop: 1 },
  },
  {
    label: "Outperformance",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Additions (Recoveries)",
    type: "decimal",
    key: "unitPriceModelOutputs.additionsRecoveries",
  },
  {
    label: "Opening Balance",
    type: "decimal",
    key: "unitPriceDataModel.previousPeriodInputs.periodUnderPerformance",
  },
  {
    label: "Closing Balance",
    type: "decimal",
    key: "unitPriceModelOutputs.outperformanceClosingBalance",
    sx: { borderBottom: 1, borderTop: 1 },
  },
  {
    label: "Unequalised Performance Fee",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Fee Percentage (including RITC) on Return Above Hurdle",
    type: "percent",
    key: "unitPriceModelOutputs.feePctOnReturnAboveHurdle",
  },
  {
    label: "Performance Fee Per Unit",
    type: "decimal",
    key: "unitPriceModelOutputs.performanceFeePerUnit",
  },
  {
    label: "Units on Issue",
    type: "integer",
    key: "unitPriceDataModel.unitsAllocations.unitsOnIssue",
  },
  {
    label: "Total Unequalised Performance Fee Accrual",
    type: "money",
    key: "unitPriceModelOutputs.totalUnequalisedPerfFee",
    sx: { borderTop: 1 },
  },
  {
    label: "Equalising Application Units",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Application Units",
    type: "integer",
    key: "unitPriceDataModel.unitsAllocations.applicationUnits",
  },
  {
    label: "Application Equalisation Daily",
    type: "money",
    key: "unitPriceModelOutputs.applicationEqualisationDaily",
  },
  {
    label: "Application Equalisation Cumulative",
    type: "money",
    key: "unitPriceModelOutputs.applicationEqualisationCumulative",
  },
  {
    label: "Total Equalised Performance Fee Accrual",
    type: "money",
    key: "unitPriceModelOutputs.totalEqualisedPerfFee",
    sx: { borderTop: 1 },
  },
  {
    label: "Crystallised Fees",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Redemption Units",
    type: "integer",
    key: "unitPriceDataModel.unitsAllocations.redemptionUnits",
  },
  {
    label: "Daily Crystallised Fee",
    type: "money",
    key: "unitPriceModelOutputs.dailyCrystallisedFee",
  },
  {
    label: "Cumulative Crystallised Fee",
    type: "money",
    key: "unitPriceModelOutputs.cumCrystallisedFee",
    sx: { borderTop: 1 },
  },
  {
    label: "Total Performance Fee",
    type: "money",
    key: "unitPriceModelOutputs.totalPerformanceFee",
    sx: { pt: 3, fontWeight: "bold" },
  },
  {
    label: "Daily Movement",
    type: "money",
    key: "unitPriceModelOutputs.dailyMovement"
  },
  {
    label: "NAV Before Performance Fee",
    type: "money",
    key: "unitPriceModelOutputs.navBeforePerfFees",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "NAV Post Performance Fee",
    type: "money",
    key: "unitPriceModelOutputs.navPostPerfFees",
    sx: { pb: 1, pt: 1, fontWeight: "bold" },
  },
  {
    label: "Unit Price Post Performance Fee",
    key: "unitPriceModelOutputs.unitPricePostPerfFees",
    sx: { pb: 1, pt: 1, fontWeight: "bold", borderTop: 2, borderBottom: 2 },
  },
  {
    label: "Month End Ex Price",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Monthly Distribution",
    key: "unitPriceModelOutputs.monthlyDistribution",
  },
  {
    label: "Unit Price Post Performance Fee - Ex (Month End)",
    key: "unitPriceModelOutputs.unitPricePostPerfFeesExMonthEnd",
    sx: { borderTop: 1},
  },
];

// Row headers for params and inputs
export const modelParamsRowHeaders: Array<RowHeader> = [
  {
    label: "Fund",
    key: "unitPriceDataModel.fundInformation.code",
    sx: { pb: 1, fontWeight: "bold" },
  },
  {
    label: "Model Timing",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Model Date",
    key: "unitPriceDataModel.modelTiming.runDate",
  },
  {
    label: "Model Start",
    key: "unitPriceDataModel.modelTiming.startDate",
  },
  {
    label: "Model End",
    key: "unitPriceDataModel.modelTiming.endDate",
  },
  {
    label: "Units & Allocations",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Units on Issue",
    type: "integer",
    key: "unitPriceDataModel.unitsAllocations.unitsOnIssue",
  },
  {
    label: "Application Units",
    type: "integer",
    key: "unitPriceDataModel.unitsAllocations.applicationUnits",
  },
  {
    label: "Redemption Units",
    type: "integer",
    key: "unitPriceDataModel.unitsAllocations.redemptionUnits",
  },
  {
    label: "Capital Flows",
    type: "money",
    key: "unitPriceDataModel.unitsAllocations.capitalFlows",
  },
  {
    label: "Distributions Payable",
    type: "money",
    key: "unitPriceDataModel.unitsAllocations.distributionsPayable",
  },
  {
    label: "Accounting Summary",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Gross Profit",
    type: "money",
    key: "unitPriceDataModel.fundFinancials.grossProfit",
  },
  {
    label: "Adjustments to Gross Profit",
    type: "money",
    key: "unitPriceDataModel.fundFinancials.adjustmentsToGrossProfit",
  },
  {
    label: "Prior Day NAV Before Performance Fees",
    type: "money",
    key: "unitPriceDataModel.fundFinancials.priorDayNavBeforePerfFee",
  },
  {
    label: "Adjustments to NAV Before Performance Fees",
    type: "money",
    key: "unitPriceDataModel.fundFinancials.adjustmentToNavBeforeFee",
  },
  {
    label: "Rates",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "BBSW rate",
    type: "percent",
    key: "unitPriceDataModel.rates.bbsw3mRate",
  },
  {
    label: "Cash Rate",
    type: "percent",
    key: "unitPriceDataModel.rates.cashRate",
  },
  {
    label: "Performance Fees",
    sx: { pb: 1, pt: 3, fontWeight: "bold" },
  },
  {
    label: "Margin",
    type: "percent",
    key: "unitPriceDataModel.fees.performance.marginAboveRate",
  },
  {
    label: "Return Above Hurdle",
    type: "percent",
    key: "unitPriceDataModel.fees.performance.returnAboveHurdle",
  },
  {
    label: "Performance Fee Floor Hurdle",
    type: "percent",
    key: "unitPriceDataModel.fees.performance.performanceFeeFloorHurdle",
  },
  {
    label: "Allowance for RITC",
    type: "decimal",
    key: "unitPriceDataModel.fees.performance.allowanceForRitc",
  },
  {
    label: "Crystallised Performance Fee",
    type: "money",
    key: "unitPriceDataModel.fees.performance.crystalisedPerformanceFee",
  },
];
