import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiErrorHandling, apiGetRequests, apiPutRequests } from "../apiUtils";
import { addNotification } from "../notifications/notificationSlice";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { FundAssumptions } from "../../models/fundDetails";

// Retrieve list of all funds
export const getFunds = createAsyncThunk(
  "fund/getFunds",
  async (_, thunkAPI) => {
    try {
      const res = await apiGetRequests("/fund", thunkAPI);
      return res.data;
    } catch (error: AxiosError | any) {
      let message = "Problem occurred retrieving list of funds";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);

// Get a single fund with its assumptions by id
export const getFund = createAsyncThunk(
  "fund/getFund",
  async (fundId: string, thunkAPI) => {
    try {
      const res = await apiGetRequests(`/fund/${fundId}`, thunkAPI);
      return res.data;
    } catch (error: AxiosError | any) {
      let message = `Problem occurred retrieving fund with ID ${fundId}`;
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);

// Update a fund
export const updateFundAssumptions = createAsyncThunk(
  "fund/updateFund",
  async ({ id, ...body }: FundAssumptions & { id: number }, thunkAPI) => {
    try {
      const response = await apiPutRequests(`/fund/${id}`, body, thunkAPI);

      thunkAPI.dispatch(
        addNotification("Fund updated successfully", "success"),
      );
      return response;
    } catch (error: AxiosError | any) {
      let message = "Problem updating fund";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);
