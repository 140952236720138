import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiErrorHandling, apiGetRequests } from "../apiUtils";

export const getImdDownload = createAsyncThunk(
  "imd/getImd",
  async (runId: number, thunkAPI) => {
    try {
      return await apiGetRequests(`/imd/${runId}`, thunkAPI, "blob");
    } catch (error) {
      let message = "Problem occurred retrieving IMD";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);
