import { useEffect } from "react";
import { useSnackbar } from "notistack";
// Store
import { useAppDispatch, useAppSelector } from "../store/store";
import {
  Notification,
  removeNotification,
} from "../store/notifications/notificationSlice";

let displayed: Array<string> = [];

/**
 * Notification (Notistack) hook for displaying and removing notifications
 */
const useNotifier = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(
    (state) => state.notifications.notifications,
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: string) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: string) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach((notification: Notification) => {
      const { key, message, options = {}, dismissed = false } = notification;
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onExited: (event, myKey) => {
          // remove this snackbar from redux
          dispatch(removeNotification(myKey as string));
          removeDisplayed(myKey as string);
        },
      });

      // keep track of snackbar's that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;
