import { Grid, Typography } from "@mui/material";
import React from "react";

interface NewRunFormLabelProps {
  label: string;
}

export default function NewRunFormLabel({ label }: NewRunFormLabelProps) {
  return (
    <Grid item xs={4}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {label}
      </Typography>
    </Grid>
  );
}
