import { ReactNode } from "react";
// material
import { Popover } from "@mui/material";

/**
 * Drop down menu wrapper
 */
export default function MenuPopover({
  children,
  sx,
  open,
  ...other
}: {
  children: ReactNode;
  sx?: any;
  open: boolean;
  [x: string]: any;
}) {
  return (
    <Popover
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{
        sx: {
          p: 1,
          width: 200,
          overflow: "inherit",
          ...sx,
        },
      }}
      open={open}
      {...other}
    >
      {children}
    </Popover>
  );
}
