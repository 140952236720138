import { Controller } from "react-hook-form";
import { FormControl, FormHelperText } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

interface DatePickerControlProps {
  name: string;
  control: any;
}

function DatePickerControl({ name, control }: DatePickerControlProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <FormControl fullWidth sx={{ height: "35px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              name="class"
              format={"yyyy/MM/dd"}
              inputRef={ref}
              value={value ? new Date(value) : null}
              onChange={onChange}
              slotProps={{ textField: { size: "small" } }}
              sx={{
                backgroundColor: "#FFFFFF",
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                border: "1px solid",
                borderRadius: "8px",
                borderColor: error ? "red" : "#CBCBCB",
                height: "34px",
              }}
            />
            <FormHelperText
              sx={{
                color: "error.main",
                width: "250px",
              }}
            >
              {error?.message ?? ""}
            </FormHelperText>
          </LocalizationProvider>
        </FormControl>
      )}
    />
  );
}

export default DatePickerControl;
