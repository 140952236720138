import _ from "lodash";
import { FundAssumptions } from "../../models/fundDetails";

export const hasUserEditedForm = (
  formData: FundAssumptions,
  initialFundAssumptions: FundAssumptions | undefined,
) => {
  const keys: (keyof FundAssumptions)[] = ["fees", "previousPeriodInputs"];

  return !keys.every((key) => {
    const formSubData = formData[key];
    const initialSubData = initialFundAssumptions?.[key];

    return Object.keys(formSubData).every((subKey) => {
      const formValue: any = formSubData[subKey as keyof typeof formSubData];
      const initialValue: any =
        initialSubData?.[subKey as keyof typeof initialSubData];
      return _.isEqual(formValue, initialValue);
    });
  });
};
