import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { deleteDistribution } from "../../store/distribution/distributionFunction";
import { Distribution } from "../../models/distribution";
import ProgressButton from "../ProgressButton/ProgressButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

type DeleteModalProps = {
  open: boolean;
  handleClose: () => void;
  distribution: Distribution;
};

export default function DistributionDeleteModal({
  open,
  handleClose,
  distribution,
}: DeleteModalProps) {
  const dispatch = useAppDispatch();
  const isSaving = useAppSelector((state) => state.distributions.loading);
  const handleDelete = async () => {
    await dispatch(deleteDistribution(distribution.id));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Email Recipient</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove this recipient?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <ProgressButton
          inProgress={isSaving}
          text="Delete"
          Icon={DeleteOutlineIcon}
          color="#ffffff"
          backgroundColor="#e60000"
          onClick={handleDelete}
        />
      </DialogActions>
    </Dialog>
  );
}
