import {
  differenceInCalendarDays,
  format,
  isBefore,
  isSameDay,
  isValid,
} from "date-fns";

// Checks if string is a valid date
export function checkValidDate(date: string | Date | null): boolean {
  if (typeof date === "string") date = new Date(date);
  return isValid(date);
}

// Checks if date one is the same as date two
export function checkDateSame(
  date: string | number | Date,
  dateAfter: string | number | Date,
): boolean {
  if (typeof date === "string") date = new Date(date);
  if (typeof dateAfter === "string") dateAfter = new Date(dateAfter);
  return isSameDay(date, dateAfter);
}

// Checks if date one is before date two
export function checkDateBefore(
  date: string | number | Date,
  dateAfter: string | number | Date,
): boolean {
  if (typeof date === "string") date = new Date(date);
  if (typeof dateAfter === "string") dateAfter = new Date(dateAfter);
  return isBefore(date, dateAfter);
}

// Checks if date one is same or before date two
export function checkDateSameOrBefore(
  date: string | number | Date,
  dateAfter: string | number | Date,
): boolean {
  if (typeof date === "string") date = new Date(date);
  if (typeof dateAfter === "string") dateAfter = new Date(dateAfter);
  return isBefore(date, dateAfter) || isSameDay(date, dateAfter);
}

// Check date between or same as the last
export function checkDateBetween(
  date: string | number | Date,
  before: string | number | Date,
  after: string | number | Date,
) {
  if (typeof date === "string") date = new Date(date);
  if (typeof before === "string") before = new Date(before);
  if (typeof after === "string") after = new Date(after);

  return (
    (isBefore(date, after) || isSameDay(date, after)) &&
    (isBefore(before, date) || isSameDay(date, before))
  );
}

// Formats date to string
export function formatDate(
  date: Date | string | number,
  dateFormat = "dd-MM-yyyy",
): string {
  try {
    if (!!date && date !== "") {
      if (typeof date === "string") {
        return format(new Date(date), dateFormat);
      } else {
        return format(date, dateFormat);
      }
    } else {
      return "";
    }
  } catch (e) {
    return "N/A";
  }
}

// Add days to date
export function addDays(date: Date | string | number, days: number) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

// Date sort comparator
export function dateSortComparator(
  a: Date | string | number,
  b: Date | string | number,
) {
  if (checkDateBefore(a, b)) return -1;
  else if (checkDateBefore(b, a)) return 1;
  else return 0;
}

// Return last business day of month, does not factor public holidays
export function getLastBusinessDayOfMonth(date: string | Date): Date {
  let lastDayOfMonth = new Date(date);
  lastDayOfMonth = new Date(
    lastDayOfMonth.getFullYear(),
    lastDayOfMonth.getMonth() + 1,
    0,
  );

  // If the last day of the month is a Saturday, move back to Friday
  if (lastDayOfMonth.getDay() === 6) {
    lastDayOfMonth = addDays(lastDayOfMonth, -1);
  }
  // If the last day of the month is a Sunday, move back to Friday
  else if (lastDayOfMonth.getDay() === 0) {
    lastDayOfMonth = addDays(lastDayOfMonth, -2);
  }

  return lastDayOfMonth;
}

// Return number of days in financial year given a date
export function daysInYear(date?: string | Date) {
  let newDate = date ? new Date(date) : new Date();
  if (newDate.getMonth() > 5) {
    newDate.setFullYear(newDate.getFullYear() + 1);
  }
  return new Date(newDate.getFullYear(), 1, 29).getMonth() === 1 ? 366 : 365;
}

// Return the previous business day. This does not factor public holidays
export function getPreviousBusinessDay(dateInput?: string | Date): Date {
  let date = dateInput ? new Date(dateInput) : new Date();

  if (date.getDay() === 0) {
    date.setDate(date.getDate() - 2); // If Sunday, Friday
  } else if (date.getDay() === 1) {
    date.setDate(date.getDate() - 3); // If Monday, Friday
  } else {
    date.setDate(date.getDate() - 1); // Else, yesterday
  }

  return date;
}

// Return start of financial year given a date
export function getStartFinancialYear(dateInput?: string | Date): Date {
  let date = dateInput ? new Date(dateInput) : getPreviousBusinessDay();
  if (date.getMonth() < 6) {
    date.setFullYear(date.getFullYear() - 1);
  }
  date.setDate(1);
  date.setMonth(6);
  return date;
}

// Return end of financial year given a date
export function getEndFinancialYear(dateInput?: string | Date): Date {
  let date = dateInput ? new Date(dateInput) : getPreviousBusinessDay();
  if (date.getMonth() > 5) {
    date.setFullYear(date.getFullYear() + 1);
  }
  date.setDate(30);
  date.setMonth(5);
  return date;
}

// Coverts DMY date format with "=" to a Date string
export function convertDMYDate(date: string) {
  const dateParts = date.split("-");

  return new Date(
    +dateParts[2],
    +dateParts[1] - 1,
    +dateParts[0],
  ).toDateString();
}

// Get yyyy-MM-dd date from a given timestamp or date
export function convertToDateString(date: Date | string | number): string {
  return format(new Date(date), "yyyy-MM-dd");
}

// Difference in days between dates
export function daysBetweenDates(
  startDate: Date | number,
  laterDate: Date | number,
) {
  return differenceInCalendarDays(laterDate, startDate);
}

// Adds months to data adjusts to remove month rollover if end of the month
export function addMonthsToDate(
  startDate: Date | number,
  months: number,
): Date | number {
  const date = new Date(startDate);
  const day = date.getDate();

  date.setMonth(date.getMonth() + months);

  if (date.getDate() !== day) {
    date.setDate(0);
  }

  return date.getTime();
}
