import { Box, BoxProps, styled } from "@mui/material";

const OutlinedContainer = styled(Box)({
  width: "100%",
  height: "100%",
  padding: "0px 24px 10px 24px",
});

const InnerContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  padding: "10px 6px 6px 6px",
  backgroundColor: "#F7F7F8", // move into theme
  borderRadius: "8px",
});

export default function BorderedContainer({ children, ...props }: BoxProps) {
  return (
    <OutlinedContainer {...props}>
      <InnerContainer>{children}</InnerContainer>
    </OutlinedContainer>
  );
}
