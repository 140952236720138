// Components
import { Logo } from "./index";
//@MUI
import { AppBar, Box, Toolbar } from "@mui/material";

// Generic TopBar Component for UI Layout
function SimpleTopBar() {
  return (
    <>
      <AppBar position="static">
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Logo />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default SimpleTopBar;
