import axios, { AxiosError, isAxiosError } from "axios";
import { setUnauthenticated } from "./user/userFunction";
import { addNotification } from "./notifications/notificationSlice";
import { GetThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";

const axiosConfigs = {
  auth: {
    baseURL: process.env.REACT_APP_AWS_MCP_API_AUTH,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_AWS_MCP_API_KEY_AUTH,
    },
    withCredentials: true,
  },
  mipad: {
    baseURL: process.env.REACT_APP_AWS_MCP_API_MIPAD,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_AWS_MCP_API_KEY_MIPAD,
    },
    withCredentials: true,
  },
  unitprice: {
    baseURL: process.env.REACT_APP_UNIT_PRICE_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  },
};

// GENERIC HANDLER FOR AUTH API GET REQUESTS
export async function apiAuthGetRequests(query: string): Promise<any> {
  const response = await axios.get(query, axiosConfigs.auth);
  return response.data;
}

// ----------------------------------------------------------------------------------------------------------

// GENERIC HANDLER FOR MIPAD API GET REQUESTS
export async function apiMipadGetRequests(query: string): Promise<any> {
  const response = await axios.get(query, axiosConfigs.mipad);
  return response.data;
}

// GENERIC HANDLER FOR MIPAD API POST REQUESTS
export async function apiMipadPostRequests(
  query: string,
  body?: any,
): Promise<any> {
  const response = await axios.post(query, body, axiosConfigs.mipad);
  return response.data;
}

// ----------------------------------------------------------------------------------------------------------

// GENERIC HANDLER FOR ALL API GET REQUESTS
export async function apiGetRequests(
  query: string,
  thunkAPI?: GetThunkAPI<any>,
  responseType?: string,
): Promise<any> {
  try {
    let config: any = axiosConfigs.unitprice;
    if (responseType) config = { ...config, responseType };
    return await axios.get(query, config);
  } catch (error) {
    if (thunkAPI) {
      throw apiErrorExpiryCheck(error, thunkAPI);
    } else {
      throw error;
    }
  }
}

// GENERIC HANDLER FOR ALL API POST REQUESTS
export async function apiPostRequests(
  query: string,
  body?: any,
  thunkAPI?: GetThunkAPI<any>,
  headers?: any,
): Promise<any> {
  try {
    let config = axiosConfigs.unitprice;
    if (headers) config = { ...config, headers };
    const response = await axios.post(query, body, config);
    return response.data;
  } catch (error) {
    if (thunkAPI) {
      throw apiErrorExpiryCheck(error, thunkAPI);
    } else {
      throw error;
    }
  }
}

// GENERIC HANDLER FOR ALL API PUT REQUESTS
export async function apiPutRequests(
  query: string,
  body?: any,
  thunkAPI?: GetThunkAPI<any>,
): Promise<any> {
  try {
    const response = await axios.put(query, body, axiosConfigs.unitprice);
    return response.data;
  } catch (error) {
    if (thunkAPI) {
      throw apiErrorExpiryCheck(error, thunkAPI);
    } else {
      throw error;
    }
  }
}

// GENERIC HANDLER FOR ALL API DELETE REQUESTS
export async function apiDeleteRequests(
  query: string,
  thunkAPI?: GetThunkAPI<any>,
): Promise<any> {
  try {
    const response = await axios.delete(query, axiosConfigs.unitprice);
    return response.data;
  } catch (error) {
    if (thunkAPI) {
      throw apiErrorExpiryCheck(error, thunkAPI);
    } else {
      throw error;
    }
  }
}

// ----------------------------------------------------------------------------------------------------------

// CHECK AND HANDLE USER ACCESS EXPIRY
export function apiErrorExpiryCheck(
  error: unknown,
  thunkAPI: GetThunkAPI<any>,
) {
  if (isAxiosError(error)) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(setUnauthenticated());
      thunkAPI.dispatch(
        addNotification("Session expired! Redirecting...", "warning"),
      );
      return new Error("AUTH_ERROR");
    }
  }

  return error;
}

// Handling for API errors
export function apiErrorHandling(
  error: unknown,
  message: string,
  thunkAPI: GetThunkAPI<any>,
) {
  let errorMessage = message;
  if (error instanceof Error && error.message !== "AUTH_ERROR") {
    if (error instanceof AxiosError && error.response?.data) {
      errorMessage = `Error: ${error.response?.data}`;
    } else {
      errorMessage = `Error: ${error.message}`;
    }
    thunkAPI.dispatch(addNotification(errorMessage, "error"));
  }
  return thunkAPI.rejectWithValue(errorMessage);
}
