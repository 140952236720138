import { Accordion, AccordionProps, styled } from "@mui/material";

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion
    disableGutters
    elevation={0}
    sx={{ "&:before": { height: "0px" } }}
    {...props}
  />
))``;

export default StyledAccordion;
