import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../../layouts/components";
import PageTitle from "../../components/PageTitle/PageTitle";
import BorderedContainer from "../../components/BorderedContainer/BorderedContainer";
import ControlBar from "../../components/ControlBar/ControlBar";
import ControlBarSection from "../../components/ControlBarSection/ControlBarSection";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { useAppSelector } from "../../store/store";
import _ from "lodash";
import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import UnitPriceDataGrid from "../../components/DataGrid/DataGrid";
import SearchControl from "../../components/SearchControl/SearchControl";
import { FundDetails } from "../../models/fundDetails";

const StyledSelect = styled(Select)({
  backgroundColor: "#FFFFFF",
  border: "1px solid",
  borderColor: "#EEEEEE",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

const ActionButtons = ({ fundDetails }: { fundDetails: FundDetails }) => {
  const [editHover, setEditHover] = useState(false);

  const navigate = useNavigate();

  return (
    <IconButton
      size="small"
      disableRipple
      onMouseEnter={() => setEditHover(true)}
      onMouseLeave={() => setEditHover(false)}
      onClick={() => {
        navigate(`/app/funds/${fundDetails.fundId}/edit`);
      }}
    >
      <EditIcon
        style={{ color: editHover ? "#2040CD" : "#BBBBBB" }}
        fontSize="small"
      />
    </IconButton>
  );
};

export default function Funds() {
  const [selectedFundType, setSelectedFundType] = React.useState("");
  const [searchText, setSearchText] = useState("");

  const funds = useAppSelector((state) => state.funds).funds;
  const fundTypes = useMemo(
    () => _.uniq(funds.map((fund) => fund.fund.type)),
    [funds],
  );

  const handleSelectedFundTypeChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedFundType(event.target.value as string);
  };

  const filteredFunds = funds.filter((fund) => {
    // If a fund is selected, filter by fund
    const fundMatches =
      selectedFundType === fund.fund.type || selectedFundType === "";

    // If a search text is entered, filter by search text
    const searchMatches = fund.fund.name
      .toLowerCase()
      .includes(searchText.toLowerCase());

    return fundMatches && searchMatches;
  });

  let columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: (params) => {
        return <div style={{ fontWeight: "bold" }}>{params.row.fund.name}</div>;
      },
    },
    {
      field: "code",
      headerName: "Fund Code",
      flex: 1,
      valueGetter: (params) => params.row.fund.code,
    },
    {
      field: "lastModifiedTimestamp",
      headerName: "Last modified",
      flex: 1,
      valueFormatter: (params) =>
        moment(params.value).format("DD MMM YY, HH:mm"),
    },
    {
      field: "lastModifiedBy",
      headerName: "Last modified by",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "",
      renderCell: (params) => <ActionButtons fundDetails={params.row} />,
    },
  ];

  return (
    <Page title="Funds">
      <PageTitle title="Funds" />
      <BorderedContainer>
        <ControlBar>
          <ControlBarSection>
            <Box display="flex" alignItems="center">
              <div style={{ width: "50px" }}>Sort by</div>
            </Box>
            <FormControl
              className="control-bar-section-item"
              fullWidth
              size="small"
            >
              <StyledSelect
                labelId="fund-type-select-label"
                id="funds-type-select"
                label="FundTypes"
                displayEmpty
                value={selectedFundType} // Set the value prop to selectedFund
                onChange={handleSelectedFundTypeChange} // Set the onChange prop to handleSelectedFundChange
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">Fund Types</MenuItem>
                {fundTypes.map((fundType, index) => (
                  <MenuItem key={index} value={fundType}>
                    {fundType}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </ControlBarSection>
          <ControlBarSection>
            <SearchControl
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </ControlBarSection>
        </ControlBar>
        <UnitPriceDataGrid columns={columns} rows={filteredFunds} />
      </BorderedContainer>
    </Page>
  );
}
