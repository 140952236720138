export function handleDownloadFile(fileName: string, fileContent: Blob) {
  const href = URL.createObjectURL(fileContent);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
