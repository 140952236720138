import { Grid, GridProps } from "@mui/material";

interface NewRunInputGridItemProps extends GridProps {
  children: React.ReactNode;
}

export default function NewRunInputGridItem({
  children,
  ...props
}: NewRunInputGridItemProps) {
  return (
    <Grid item xs={8} {...props}>
      {children}
    </Grid>
  );
}
