import { createSlice, Draft, nanoid, PayloadAction } from "@reduxjs/toolkit";
// -----------------------------------------------------------------------------------

export interface Notification {
  key: string;
  message: string;
  options: any;
  dismissed?: boolean;
}

type variants = "default" | "error" | "success" | "warning" | "info";

export interface NotificationState {
  // notification: Notification | null
  notifications: Array<Notification>;
}

// INITIAL STATE
const initialState: NotificationState = {
  notifications: [],
};

// REDUCER
export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    // ADD NOTIFICATION
    addNotification: {
      reducer(
        state: Draft<NotificationState>,
        action: PayloadAction<Notification>,
      ) {
        state.notifications.push(action.payload);
      },
      // FORMULATES NOTIFICATION - attached default settings and generates ID
      prepare(message: string, variant: variants, persist: boolean = false) {
        return {
          payload: {
            key: nanoid(),
            message,
            options: {
              variant,
              persist,
              preventDuplicate: true,
            },
          },
        };
      },
    },
    // CLOSE NOTIFICATIONS
    closeNotification: {
      reducer(
        state: Draft<NotificationState>,
        action: PayloadAction<{ dismissAll: boolean; key?: string }>,
      ) {
        state.notifications = state.notifications.map((notification) =>
          action.payload.dismissAll || notification.key === action.payload.key
            ? { ...notification, dismissed: true }
            : { ...notification },
        );
      },
      // DETERMINES WHETHER TO CLOSE ALL OR A SINGLE NOTIFICATION
      prepare(key?: string) {
        return {
          payload: {
            dismissAll: !key,
            key: key,
          },
        };
      },
    },

    // REMOVES NOTIFICATION AFTER IT EXPIRES
    removeNotification: (
      state: Draft<NotificationState>,
      action: PayloadAction<string>,
    ) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== action.payload,
      );
    },
  },
});

export const { addNotification, closeNotification, removeNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
