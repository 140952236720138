// SpacedGridContainer.tsx
import { Grid, GridProps } from "@mui/material";

export default function MultiColumnContainer({
  children,
  ...props
}: GridProps) {
  return (
    <Grid container spacing={2} {...props}>
      {children}
    </Grid>
  );
}
