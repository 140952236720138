import { Box, styled } from "@mui/material";

interface ControlBarSectionProps {
  children: React.ReactNode;
}

const StyledControlBarSection = styled(Box)({
  display: "flex",
  gap: "10px",
});

export default function ControlBarSection({
  children,
}: ControlBarSectionProps) {
  return <StyledControlBarSection>{children}</StyledControlBarSection>;
}
