import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface Version {
  tag: string;
}

const initialState: Version = {
  tag: "0.0.0",
};

export const versionSlice = createSlice({
  name: "version",
  initialState,
  reducers: {
    addVersion: (state, action: PayloadAction<Version>) => {
      state.tag = action.payload.tag;
    },
  },
});

export const { addVersion } = versionSlice.actions;

export default versionSlice.reducer;
