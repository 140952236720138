import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  Box,
  Breadcrumbs,
  Container,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Page } from "../../layouts/components";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import BorderedContainer from "../../components/BorderedContainer/BorderedContainer";
import ControlBar from "../../components/ControlBar/ControlBar";
import ControlBarSection from "../../components/ControlBarSection/ControlBarSection";
import ProgressButton from "../../components/ProgressButton/ProgressButton";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import EditFundForm from "./EditFundForm";
import ContainerBox from "../../components/ContainerBox/ContainerBox";
import { getFund, updateFundAssumptions } from "../../store/fund/fundFunction";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FundDetails } from "../../models/fundDetails";
import { z } from "zod";
import { EditFormSchema } from "./EditFundSchema";
import getEditFundDefaultValues from "./EditFundDefaultValues";
import { hasUserEditedForm } from "./EditFundHasUserEditedForm";

const ErrorHandlerBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "60vh",
}));

export default function EditFund() {
  const theme = useTheme();
  const { fundId } = useParams();
  const [attemptedEdit, setAttemptedEdit] = React.useState(false);
  const isSaving = useAppSelector((state) => state.funds.isUpdatingFund);
  const [initialFundDetails, setInitialFundDetails] =
    React.useState<FundDetails | null>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  type FormData = z.infer<typeof EditFormSchema>;

  const fund: FundDetails | undefined = useAppSelector(
    (state) => state.funds,
  ).funds.find((fund) => String(fund.fundId) === fundId);

  const fundDetails = useAppSelector((state) => state.funds.selectedFund);

  const actions = useAppSelector((state) =>
    state.user.user?.permissions.actions.map((a) => a.permission),
  );

  const { control, handleSubmit, reset, watch, trigger } = useForm<FormData>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(EditFormSchema),
    defaultValues: getEditFundDefaultValues(initialFundDetails),
  });

  const formValues = watch();

  // Get the fund details when the component mounts
  useEffect(() => {
    dispatch(getFund(String(fundId)));
  }, [fundId, dispatch]);

  // Reset the form when new fund details are fetched
  useEffect(() => {
    if (fundDetails && Object.keys(fundDetails).length > 0) {
      setInitialFundDetails(fundDetails);
      setAttemptedEdit(false);
      reset(getEditFundDefaultValues(fundDetails));
    }
  }, [fundDetails, reset]);

  // Call trigger to ensure zod errors are shown in realtime, to force form validation when form values change
  useEffect(() => {
    trigger();
  }, [formValues, trigger]);

  return (
    <Page title="EditFund">
      <Container maxWidth={false} sx={{ paddingTop: "16px" }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ marginBottom: "16px" }}
        >
          <Link style={{ color: theme.palette.text.primary }} to="/app/funds">
            <Typography variant="subtitle1">Funds</Typography>
          </Link>
          <Typography variant="h6" color="textPrimary">
            {fund ? `${fund.fund.name} (${fund.fund.code})` : "Invalid Fund"}
          </Typography>
        </Breadcrumbs>
      </Container>
      <BorderedContainer>
        <form
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
          onSubmit={handleSubmit(async (data) => {
            setAttemptedEdit(true);
            if (hasUserEditedForm(data, initialFundDetails?.assumptions)) {
              await dispatch(
                updateFundAssumptions({
                  id: Number(initialFundDetails?.fundId),
                  ...data,
                }),
              );
              navigate("/app/funds");
            }
          })}
        >
          <ControlBar>
            <ControlBarSection>
              <span></span>
            </ControlBarSection>
            {actions && actions.some((a) => a === "up_edit_all") && (
              <ControlBarSection>
                {attemptedEdit &&
                  !hasUserEditedForm(
                    formValues,
                    initialFundDetails?.assumptions,
                  ) && (
                    <Typography
                      color="error"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      Please modify a value before submitting.
                    </Typography>
                  )}
                <ProgressButton
                  disabled={fund === undefined}
                  inProgress={isSaving}
                  text={"Save"}
                  Icon={SaveAltIcon}
                  color="#ffffff"
                  backgroundColor="#2040cd"
                ></ProgressButton>
              </ControlBarSection>
            )}
          </ControlBar>
          <ContainerBox>
            {!fund ? (
              <ErrorHandlerBox>
                Invalid Fund ID, select another fund
              </ErrorHandlerBox>
            ) : (
              <EditFundForm
                control={control}
                formValues={formValues}
              ></EditFundForm>
            )}
          </ContainerBox>
        </form>
      </BorderedContainer>
    </Page>
  );
}
