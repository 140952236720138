// MUI
import { styled } from "@mui/material/styles";
import { CSSObject, Divider, Drawer as MuiDrawer, Theme } from "@mui/material";
// components
import navConfig from "./NavConfig";
import NavSection from "./NavSection";

// ----------------------------------------------------------------------

export const DRAWER_WIDTH = 205;

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// ----------------------------------------------------------------------

export default function DashboardSidebar({
  open,
  onOpen,
}: {
  open: boolean;
  onOpen: Function;
}) {
  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        zIndex: 1000,
        [`& .MuiDrawer-paper`]: { bgcolor: "primary.dark" },
      }}
    >
      <DrawerHeader />
      <Divider />
      <NavSection navConfig={navConfig} open={open} onOpen={onOpen} />
    </Drawer>
  );
}
