import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// MUI
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Tooltip,
  Divider,
} from "@mui/material";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  FirstPage as CollapseIcon,
  LastPage as OpenIcon,
} from "@mui/icons-material";

/**s
 * Formats SideBar navigation
 */
// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 36,
  position: "relative",
  color: theme.palette.common.white,
  padding: theme.spacing(1),
}));

const ListItemIconStyle = styled(ListItemIcon)({
  color: "inherit",
  // display: 'flex',
  // alignItems: 'center',
});

// ----------------------------------------------------------------------

export interface Item {
  title: string;
  path: string;
  icon: string | JSX.Element;
  children?: Item[];
}

// Color mapping for Nav Item nesting, currently only 2 layers
const childItemPalette = [
  {
    parent: {
      active: "secondary.main",
      default: "primary.dark",
      text: "common.white",
    },
    child: {
      active: "secondary.light",
      activeText: "common.white",
    },
  },
  {
    parent: {
      active: "secondary.light",
      default: "common.white",
      text: "secondary.light",
    },
    child: {
      active: "primary.lighter",
      activeText: "secondary.light",
    },
  },
];

// Handles each navigational menu item in the sidebar, handling collapsible menu items
function NavItem({
  item,
  active,
  open,
  depth = 0,
}: {
  item: Item;
  active: Function;
  open: boolean;
  depth?: number;
}) {
  const isActiveRoot = active(item.path);

  const { title, path, icon, children } = item;

  const activeParentStyle = {
    fontWeight: "fontWeightMedium",
    bgcolor: childItemPalette[depth].parent.active,
    color: "common.white",
  };

  const activeChildStyle = {
    color: childItemPalette[depth].child.activeText,
    fontWeight: "fontWeightMedium",
    bgcolor: childItemPalette[depth].child.active,
  };

  if (children) {
    return (
      <>
        {/* @ts-ignore */}
        <ListItemStyle
          component={RouterLink}
          to={path}
          sx={{
            color: childItemPalette[depth].parent.text,
            bgcolor: childItemPalette[depth].parent.default,
            ...(isActiveRoot && activeParentStyle),
            "&:hover": activeParentStyle,
            justifyContent: open ? "flex-start" : "center",
          }}
        >
          <Tooltip title={title} placement="right">
            <ListItemIconStyle
              sx={{
                justifyContent: open ? "flex-start" : "center",
              }}
            >
              {icon && icon}
            </ListItemIconStyle>
          </Tooltip>
          {open && (
            <>
              <ListItemText
                disableTypography
                primary={title}
                sx={{ fontSize: "12px" }}
              />
              {isActiveRoot ? (
                <KeyboardArrowDownIcon sx={{ width: 12, height: 12 }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ width: 12, height: 12 }} />
              )}
            </>
          )}
        </ListItemStyle>

        <Collapse in={isActiveRoot} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item: Item) => {
              const isActiveSub = active(item.path);

              if (item.children) {
                return (
                  <NavItem
                    item={item}
                    active={active}
                    key={item.title}
                    depth={depth + 1}
                    open={open}
                  />
                );
              } else {
                return (
                  // @ts-ignore
                  <ListItemStyle
                    key={item.title}
                    component={RouterLink}
                    to={item.path}
                    sx={{
                      bgcolor: "common.white",
                      color: "primary.main",
                      "&:hover": activeChildStyle,
                      ...(isActiveSub && activeChildStyle),
                    }}
                  >
                    <Tooltip
                      title={`${title} - ${item.title}`}
                      placement="right"
                    >
                      <ListItemIconStyle
                        sx={{
                          justifyContent: open ? "flex-start" : "center",
                        }}
                      >
                        {item.icon && item.icon}
                      </ListItemIconStyle>
                    </Tooltip>
                    {open && (
                      <ListItemText
                        disableTypography
                        primary={item.title}
                        sx={{ fontSize: "12px" }}
                      />
                    )}
                  </ListItemStyle>
                );
              }
            })}
          </List>
          <Divider />
        </Collapse>
      </>
    );
  }

  return (
    // @ts-ignore
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        color: childItemPalette[depth].parent.text,
        bgcolor: childItemPalette[depth].parent.default,
        ...(isActiveRoot && activeParentStyle),
        justifyContent: open ? "flex-start" : "center",
      }}
    >
      <Tooltip title={title} placement="right">
        <ListItemIconStyle
          sx={{
            justifyContent: open ? "flex-start" : "center",
          }}
        >
          {icon && icon}
        </ListItemIconStyle>
      </Tooltip>
      {open && (
        <ListItemText
          disableTypography
          primary={title}
          sx={{ fontSize: "12px" }}
        />
      )}
    </ListItemStyle>
  );
}

function CollapseItem({ open, onOpen }: { open: boolean; onOpen: Function }) {
  return (
    // @ts-ignore
    <ListItemStyle
      onClick={onOpen}
      sx={{
        justifyContent: "flex-end",
      }}
    >
      <Tooltip title={"Collapse"} placement="right">
        <ListItemIconStyle
          sx={{
            justifyContent: open ? "flex-end" : "center",
          }}
        >
          {open ? <CollapseIcon /> : <OpenIcon />}
        </ListItemIconStyle>
      </Tooltip>
    </ListItemStyle>
  );
}

// Dashboard left sidebar menu, displays navigations
export default function NavSection({
  navConfig,
  open,
  onOpen,
  ...other
}: {
  navConfig: Item[];
  open: boolean;
  onOpen: Function;
  [x: string]: any;
}) {
  const { pathname } = useLocation();

  // Function to check paths match
  const match = (path: string) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box
      {...other}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: 1,
      }}
    >
      <List disablePadding>
        {navConfig.map((item: Item) => (
          <NavItem key={item.title} item={item} active={match} open={open} />
        ))}
      </List>
      <List disablePadding>
        <CollapseItem open={open} onOpen={onOpen} />
      </List>
    </Box>
  );
}
