import { Navigate, Route, Routes } from "react-router-dom";
import Runs from "./Runs";
import Funds from "./Funds";
import Distribution from "./Distribution";
import EditFund from "../../forms/EditFund/EditFund";

export default function UnitPricing() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={"runs"} replace={true} />} />
      <Route path="/runs" element={<Runs />} />
      <Route path="/funds" element={<Funds />} />
      <Route path="/funds/:fundId/edit" element={<EditFund />} />
      <Route path="/distribution" element={<Distribution />} />
    </Routes>
  );
}
