import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";

interface StyledDatePickerProps {
  value: any;
  onChange: any;
  disabled?: boolean;
}

export default function StyledDatePicker({
  value,
  onChange,
  disabled,
}: StyledDatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={onChange}
        disabled={disabled}
        format="yyyy-MM-dd"
        sx={{
          backgroundColor: "white",
        }}
        slotProps={{
          textField: {
            size: "small",
            fullWidth: true,
          },
          day: {
            sx: {
              "&.Mui-disabled": {
                backgroundColor: "white",
              },
              "&.Mui-selected": {
                backgroundColor: "primary.main",
                opacity: 1,
              },
              "&.MuiButtonBase-root-MuiPickersDay-root": {
                opacity: 1,
              },
              "&.MuiPickersDay-today": {
                border: "none",
              },
              backgroundColor: "grey.200",
              opacity: "1 !important",
              fontWeight: "bold",
              color: "primary.main",
              borderRadius: 1,
              "&:hover": {
                backgroundColor: "primary.light",
                color: "white",
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}
