import { Box, styled } from "@mui/material";

interface ControlBarProps {
  children: React.ReactNode;
}

const StyledControlBar = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "0px 4px 8px 4px",
});

export default function ControlBar({ children }: ControlBarProps) {
  return <StyledControlBar>{children}</StyledControlBar>;
}
