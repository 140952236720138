import { Grid, Typography } from "@mui/material";

interface FormLabelProps {
  label: string;
}

export default function FormLabel({ label }: FormLabelProps) {
  return (
    <Grid item xs={6}>
      <Typography variant="body1">{label}</Typography>
    </Grid>
  );
}
