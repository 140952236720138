// MUI
import { LibraryBooksTwoTone as BookIcon } from "@mui/icons-material";
// Components
import { Item } from "./NavSection";

// ----------------------------------------------------------------------

const navConfig: Item[] = [
  {
    title: "Runs",
    path: "/app/runs",
    icon: <BookIcon />,
  },
  {
    title: "Funds Details",
    path: "/app/funds",
    icon: <BookIcon />,
  },
  {
    title: "Email Distribution",
    path: "/app/distribution",
    icon: <BookIcon />,
  },
];

export default navConfig;
