import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiErrorHandling, apiGetRequests } from "../apiUtils";

export const getExcelModel = createAsyncThunk(
  "excelModel/getExcelModel",
  async (runId: number, thunkAPI) => {
    try {
      return await apiGetRequests(`/excelModel/${runId}`, thunkAPI, "blob");
    } catch (error) {
      let message = "Problem occurred retrieving Excel Model";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);
