import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiDeleteRequests,
  apiErrorHandling,
  apiGetRequests,
  apiPostRequests,
  apiPutRequests,
} from "../apiUtils";
import { addNotification } from "../notifications/notificationSlice";
import { DistributionPostModel } from "../../models/distributionPostModel";

// Retrieve Distributions
export const getDistributions = createAsyncThunk(
  "distribution/getDistributions",
  async (_, thunkAPI) => {
    try {
      const res = await apiGetRequests("/distribution", thunkAPI);
      return res.data;
    } catch (error) {
      let message = "Problem getting distribution";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);

// Add a distribution
export const addDistribution = createAsyncThunk(
  "/distribution/postDistribution",
  async (body: DistributionPostModel, thunkAPI) => {
    try {
      var response = await apiPostRequests("/distribution", body, thunkAPI);

      // UI Notification
      thunkAPI.dispatch(
        addNotification("Distribution added successfully", "success"),
      );
      return response;
    } catch (error) {
      let message = "Problem adding distribution";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);

// Update a distribution
export const updateDistribution = createAsyncThunk(
  "/distribution/updateDistribution",
  async ({ id, ...body }: DistributionPostModel & { id: number }, thunkAPI) => {
    try {
      var response = await apiPutRequests(
        `/distribution/${id}`,
        body,
        thunkAPI,
      );

      // UI Notification
      thunkAPI.dispatch(
        addNotification("Distribution updated successfully", "success"),
      );
      return response;
    } catch (error) {
      let message = "Problem updating distribution";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);

// Delete a distribution
export const deleteDistribution = createAsyncThunk(
  "/distribution/deleteDistribution",
  async (id: number, thunkAPI) => {
    try {
      var response = await apiDeleteRequests(`/distribution/${id}`, thunkAPI);

      // UI Notification
      thunkAPI.dispatch(addNotification("Distribution removed", "success"));
      return response;
    } catch (error) {
      let message = "Problem removing distribution";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);
