export function fCurrency(number: number) {
  if (!number) return "-";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
  }).format(number);
}

// Summaries' larger numbers values with notation and formats into currency.
export function fRoundedCurrency(number: number, decimals: number = 2) {
  let value;
  let notation = "";

  switch (true) {
    case Math.abs(number) >= 1000000000:
      value = number / 1000000000;
      notation = "B";
      break;

    case Math.abs(number) >= 1000000:
      value = number / 1000000;
      notation = "M";
      break;

    case Math.abs(number) >= 1000:
      value = number / 1000;
      notation = "K";
      break;

    default:
      value = number;
  }

  const string = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: decimals,
  }).format(value);

  return `${string}${notation}`;
}

export function fPercent(number: number) {
  if (!number) return "-";
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(number);
}

// Format number to rounded decimal
export function fNumber(
  number: number,
  minPrecision: number = 2,
  maxPrecision: number = 2,
): string {
  if (!number) return "-";
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }).format(number);
}
