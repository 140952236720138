import { Grid, GridProps } from "@mui/material";

export default function FormRowGridContainer({
  children,
  ...props
}: GridProps) {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{ paddingX: "10px", height: "51px", marginBottom: "16px" }}
      {...props}
    >
      {children}
    </Grid>
  );
}
