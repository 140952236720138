import { Button, Box, CircularProgress, SvgIcon, styled } from "@mui/material";
import { ElementType } from "react";

const StyledButton = styled(Button)({
  "& .progress-button-content": {
    display: "flex",
    alignItems: "center",
  },
  "& .progress-button-icon": {
    marginRight: "8px",
    fontSize: "15px",
  },
});

interface ProgressButtonProps {
  inProgress: boolean;
  text: string;
  Icon: ElementType;
  color: string;
  backgroundColor: string;
  onClick?: () => void; // Add optional onClick prop
  disabled?: boolean; // Add optional hidden prop
}

const PROGRESS_SIZE = 15; // Should match the font size of icon used as input prop for correct appearance

export default function ProgressButton({
  inProgress,
  text,
  Icon,
  color,
  backgroundColor,
  onClick,
  disabled,
}: ProgressButtonProps) {
  return (
    <StyledButton
      className="progress-button"
      style={{ backgroundColor, color }}
      variant="contained"
      type="submit"
      disabled={inProgress || disabled}
      onClick={onClick}
    >
      <Box className="progress-button-content">
        {inProgress ? (
          <CircularProgress
            color="inherit"
            size={PROGRESS_SIZE}
            className="progress-button-icon"
          />
        ) : (
          <SvgIcon className="progress-button-icon" component={Icon} />
        )}
        {text}
      </Box>
    </StyledButton>
  );
}
