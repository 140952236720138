import { Select, styled } from "@mui/material";

const StyledSelect = styled(Select)({
  backgroundColor: "#FFFFFF",
  border: "1px solid",
  borderColor: "#EEEEEE",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

export default StyledSelect;
