import "./App.css";
import { SnackbarProvider } from "notistack";
import ThemeProvider from "./theme";
import Router from "./Router/index";
// MUI
import { Clear as ClearIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
// Store
import { useAppDispatch } from "./store/store";
import { closeNotification } from "./store/notifications/notificationSlice";
import { useEffect } from "react";
import { getVersion } from "./store/version/versionFunction";
import { Version, addVersion } from "./store/version/versionSlice";
import { getRuns } from "./store/run/runFunction";
import { clearRuns } from "./store/run/runSlice";
import { clearDistributions } from "./store/distribution/distributionSlice";
import { getDistributions } from "./store/distribution/distributionFunction";
import { clearFunds } from "./store/fund/fundSlice";
import { getFunds } from "./store/fund/fundFunction";

// Root of application
function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearDistributions());
    dispatch(clearRuns());
    dispatch(clearFunds());
    const fetchVersion = async () => {
      var version = await dispatch(getVersion());
      dispatch(addVersion(version.payload as Version));
    };

    const fetchRuns = async () => {
      dispatch(getRuns(null));
    };

    const fetchDistributions = async () => {
      dispatch(getDistributions());
    };

    const fetchFunds = async () => {
      dispatch(getFunds());
    };

    fetchDistributions();
    fetchVersion();
    fetchRuns();
    fetchFunds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        action={(key) => (
          <IconButton
            sx={{ color: "common.white" }}
            onClick={() => dispatch(closeNotification(key as string))}
          >
            <ClearIcon />
          </IconButton>
        )}
      >
        <Box sx={{ minWidth: 800 }}>
          <Router />
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;

// TRIGGER BUILD
