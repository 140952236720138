import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { UserPermissions } from "../../types/UserTypes";
export function isAuthenticated(store: RootState): boolean {
  return store.user.authenticated;
}

const getUserPermissionAccess = (state: RootState): Array<UserPermissions> =>
  state.user.user?.permissions.access || [];

export const checkAppAccess = createSelector(
  [getUserPermissionAccess],
  (access) => {
    return access.some((perm) => perm.permission === "unit_price");
  },
);
