import { NewRun } from "./RunCreationModal";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ProgressButton from "../../components/ProgressButton/ProgressButton";
import { Box, Button, FormControl, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { convertToDateString } from "../../utils/DateUtils";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { postFinancialStatements } from "../../store/accounting/accountingFunctions";
import NewRunNavGridContainer from "./Components/NewRunNavGridContainer/NewRunNavGridContainer";
import NewRunInputGridItemContainer from "./Components/NewRunInputGridItemContainer/NewRunInputGridItemContainer";
import NewRunFormLabel from "./Components/NewRunFormLabel/NewRunFormLabel";
import NewRunInputGridItem from "./Components/NewRunInputGridItem/NewRunInputGridItem";
import TextField from "@mui/material/TextField";

interface SelectFinancialStatementsProps {
  activeStep: number;
  handleBack: () => void;
  handleNext: (data: { [key: string]: any }) => void;
  handleReset: () => void;
  handleClose: () => void;
  newRun: NewRun;
  netsuiteCodes: string;
}

export default function SelectFinancialStatementsStep({
  activeStep,
  handleBack,
  handleNext,
  handleReset,
  handleClose,
  newRun,
  netsuiteCodes,
}: SelectFinancialStatementsProps) {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.accounting.loading);
  const [balanceSheet, setBalanceSheet] = useState<FileList | null>(null);
  const [profitAndLoss, setProfitAndLoss] = useState<FileList | null>(null);
  const [balanceSheetCodes, setBalanceSheetCodes] =
    useState<string>(netsuiteCodes);

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (
            balanceSheet &&
            profitAndLoss &&
            balanceSheet.length &&
            profitAndLoss.length
          ) {
            const formData = new FormData();
            formData.append("fundId", newRun.fundId.toString());
            formData.append(
              "date",
              convertToDateString(newRun.modelTiming.runDate),
            );
            formData.append("balanceSheetCodes", balanceSheetCodes);
            formData.append("balanceSheet", balanceSheet[0]);
            formData.append("profitAndLoss", profitAndLoss[0]);
            let res = await dispatch(postFinancialStatements(formData));
            if (res.meta.requestStatus === "fulfilled") handleNext({});
          }
        }}
      >
        <NewRunInputGridItemContainer>
          <NewRunFormLabel
            label={"Netsuite Codes for Debt Facilities (comma separated)"}
          />
          <NewRunInputGridItem>
            <FormControl fullWidth>
              <TextField
                name="balanceSheetCodes"
                type="text"
                size="small"
                placeholder="Netsuite Item Codes"
                value={balanceSheetCodes}
                onChange={(e) => setBalanceSheetCodes(e.target.value)}
              />
            </FormControl>
          </NewRunInputGridItem>
          {/* -------------------------------------------------------------------------------------------------------------------------------------- */}
          {/* BALANCE SHEET */}
          <Grid item xs={12} sx={{ pb: 2, mt: 2 }}>
            <Typography variant="body1">
              Please upload Financial Statements in .csv format
            </Typography>
          </Grid>
          <NewRunFormLabel label={"Balance Sheet"} />
          <NewRunInputGridItem>
            <FormControl>
              <input
                name="balanceSheet"
                type="file"
                placeholder="Upload Balance Sheet"
                required
                onChange={(e) => setBalanceSheet(e.target.files)}
                accept=".xlsx,.csv"
              />
            </FormControl>
          </NewRunInputGridItem>
          {/* -------------------------------------------------------------------------------------------------------------------------------------- */}
          {/* PROFIT AND LOSS */}
          <NewRunFormLabel label={"Profit and Loss"} />
          <NewRunInputGridItem>
            <FormControl>
              <input
                name="profitAndLoss"
                type="file"
                placeholder="Upload PL Statement"
                required
                onChange={(e) => setProfitAndLoss(e.target.files)}
                accept=".xlsx,.csv"
              />
            </FormControl>
          </NewRunInputGridItem>
          {/* -------------------------------------------------------------------------------------------------------------------------------------- */}
          <NewRunNavGridContainer>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={() => {
                handleReset();
                handleClose();
              }}
              sx={{ marginRight: 1 }}
            >
              Cancel
            </Button>
            <ProgressButton
              inProgress={loading}
              text="Next"
              Icon={NavigateNextIcon}
              color="#ffffff"
              backgroundColor="#2040cd"
              disabled={loading}
            />
          </NewRunNavGridContainer>
        </NewRunInputGridItemContainer>
      </form>
    </>
  );
}
