import { Grid, GridProps } from "@mui/material";

interface NewRunInputGridItemContainerProps extends GridProps {
  children: React.ReactNode;
}

export default function NewRunInputGridItemContainer({
  children,
  ...props
}: NewRunInputGridItemContainerProps) {
  return (
    <Grid
      item
      container
      rowSpacing={2}
      alignItems="center"
      paddingTop={6}
      {...props}
    >
      {children}
    </Grid>
  );
}
