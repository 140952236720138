import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiErrorHandling, apiGetRequests } from "../apiUtils";

// RETRIEVE VERSION
export const getVersion = createAsyncThunk(
  "version/getVersion",
  async (_, thunkAPI) => {
    try {
      const res = await apiGetRequests("/version", thunkAPI);
      const tag = res.data;

      return {
        tag: tag,
      };
    } catch (error) {
      let message = "Problem occurred retrieving version";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);
