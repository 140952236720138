import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  checkUserAuth,
  getUser,
  setUnauthenticated,
  signOut,
} from "./userFunction";
import { User } from "../../types/UserTypes";

export interface UserState {
  user: null | User;
  authenticated: boolean;
  loading: boolean;
  userCheck: boolean;
}

const initialState: UserState = {
  user: null,
  authenticated: false,
  loading: false,
  userCheck: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // CHECK USER AUTHENTICATED
      .addCase(setUnauthenticated.fulfilled, (state: Draft<UserState>) => {
        state.authenticated = false;
      })

      // CHECK USER AUTHENTICATED
      .addCase(checkUserAuth.pending, (state: Draft<UserState>) => {
        state.loading = true;
      })
      .addCase(checkUserAuth.fulfilled, (state: Draft<UserState>) => {
        state.authenticated = true;
        state.loading = false;
        state.userCheck = true;
      })
      .addCase(checkUserAuth.rejected, (state: Draft<UserState>) => {
        state.authenticated = false;
        state.loading = false;
        state.userCheck = true;
      })
      // CHECK USER LOGIN
      .addCase(getUser.pending, (state: Draft<UserState>) => {
        state.loading = true;
      })
      .addCase(
        getUser.fulfilled,
        (state: Draft<UserState>, action: PayloadAction<User>) => {
          state.user = action.payload;
          state.loading = false;
        },
      )
      .addCase(getUser.rejected, (state: Draft<UserState>) => {
        state.loading = false;
      })
      // User Sign Out
      .addCase(signOut.fulfilled, (state: Draft<UserState>) => {
        state.authenticated = false;
      });
  },
});

export default userSlice.reducer;
