import React from "react";
import { FormControl, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import StyledDatePicker from "../StyledDatePicker/StyledDatePicker";

interface DatePickerControlProps {
  name: string;
  control: any;
  disabled?: boolean;
}

export default function DatePickerControl({
  name,
  control,
  disabled,
}: DatePickerControlProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <StyledDatePicker
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {error?.message ?? ""}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
