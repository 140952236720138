import { useEffect } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";

// Typing of traversable location
interface routingLocation extends Location {
  state: {
    from?: {
      pathname: string;
    };
  };
}

// Checks user if authenticated, if authenticated passes to last location or dashboard.
export function AuthenticationCheck({
  authenticated,
  children,
}: {
  authenticated: boolean;
  children: JSX.Element;
}) {
  let location = useLocation() as routingLocation;
  const navigate = useNavigate();

  // Used to reroute to page before authentication
  let from = location.state?.from?.pathname || "/app";

  // Check if Active user session
  // If user in state reroute to page
  useEffect(() => {
    if (authenticated) {
      navigate(from, { replace: true });
    }
  }, [authenticated, navigate, from]);

  // Default Navigate to unauthenticated
  return children;
}

// Checks user if authenticated grant access to children otherwise redirect back to login
export function RequireAuth({
  authenticated,
  access,
  children,
}: {
  authenticated: boolean;
  access: boolean;
  children: JSX.Element;
}) {
  let location = useLocation();
  const navigate = useNavigate();

  // If no user in state
  useEffect(() => {
    if (!authenticated) {
      navigate("/", { state: { from: location, replace: true } });
    } else if (!access && location.pathname !== "/restricted") {
      navigate("/restricted");
    } else if (access && location.pathname === "/restricted") {
      navigate("/");
    }
  }, [authenticated, navigate, location, access]);

  // Default route to authenticated page
  return children;
}
