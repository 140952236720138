import { createSlice } from "@reduxjs/toolkit";
import { getExcelModel } from "./excelModelFunctions";

const initialState: { loading: boolean } = {
  loading: false,
};

export const excelModelSlice = createSlice({
  name: "excelModel",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExcelModel.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExcelModel.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getExcelModel.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default excelModelSlice.reducer;
