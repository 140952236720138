import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getLastCompletedRun,
  getRuns,
  postPricingRun,
  putPricingRun,
} from "./runFunction";
import { Run } from "../../models/run";

const initialState: { runs: Run[]; loading: boolean } = {
  runs: [],
  loading: false,
};

export const runSlice = createSlice({
  name: "runs",
  initialState,
  reducers: {
    clearRuns: (state) => {
      state.runs = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRuns.pending, (state, action) => {
        state.runs = [];
        state.loading = true;
      })
      .addCase(getRuns.fulfilled, (state, action: PayloadAction<Run[]>) => {
        state.runs = action.payload;
        state.loading = false;
      })
      .addCase(getLastCompletedRun.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getLastCompletedRun.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postPricingRun.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postPricingRun.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(putPricingRun.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(putPricingRun.fulfilled, (state, action) => {
        state.loading = false;
      });
  },
});

export const { clearRuns } = runSlice.actions;

export default runSlice.reducer;
