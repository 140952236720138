import { FundDetails } from "../../models/fundDetails";

const getDefaultValue = (value: any, defaultValue: any) =>
  value ?? defaultValue;

export default function getEditFundDefaultValues(
  initialFundDetails: FundDetails | null | undefined,
) {
  const nonPerformance = initialFundDetails?.assumptions.fees.nonPerformance;
  const performance = initialFundDetails?.assumptions.fees.performance;
  const previousPeriodInputs =
    initialFundDetails?.assumptions.previousPeriodInputs;
  const fundInformation = initialFundDetails?.assumptions.fundInformation;

  return {
    fees: {
      nonPerformance: {
        calculationType: getDefaultValue(nonPerformance?.calculationType, ""),
        reFee: getDefaultValue(nonPerformance?.reFee, 0),
        reFeeType: getDefaultValue(nonPerformance?.reFeeType, ""),
        managementFee: getDefaultValue(nonPerformance?.managementFee, 0),
        managementFeeType: getDefaultValue(
          nonPerformance?.managementFeeType,
          "",
        ),
        adminFee: getDefaultValue(nonPerformance?.adminFee, 0),
        adminFeeType: getDefaultValue(nonPerformance?.adminFeeType, ""),
        registryFee: getDefaultValue(nonPerformance?.registryFee, 0),
        registryFeeType: getDefaultValue(nonPerformance?.registryFeeType, ""),
        ieeFee: getDefaultValue(nonPerformance?.ieeFee, 0),
        ieeFeeType: getDefaultValue(nonPerformance?.ieeFeeType, ""),
        imfFee: getDefaultValue(nonPerformance?.imfFee, 0),
        imfFeeType: getDefaultValue(nonPerformance?.imfFeeType, ""),
      },
      performance: {
        rateType: getDefaultValue(performance?.rateType, ""),
        marginAboveRate: getDefaultValue(performance?.marginAboveRate, 0),
        returnAboveHurdle: getDefaultValue(performance?.returnAboveHurdle, 0),
        performanceFeeFloorHurdle: getDefaultValue(
          performance?.performanceFeeFloorHurdle,
          0,
        ),
        allowanceForRitc: getDefaultValue(performance?.allowanceForRitc, 0),
        crystalisedPerformanceFee: getDefaultValue(
          performance?.crystalisedPerformanceFee,
          0,
        ),
        performanceFeeApplicable: getDefaultValue(
          performance?.performanceFeeApplicable,
          false,
        ),
      },
    },
    previousPeriodInputs: {
      closingDate: getDefaultValue(previousPeriodInputs?.closingDate, ""),
      closingNav: getDefaultValue(previousPeriodInputs?.closingNav, 0),
      applicationUnits: getDefaultValue(
        previousPeriodInputs?.applicationUnits,
        0,
      ),
      redemptionUnits: getDefaultValue(
        previousPeriodInputs?.redemptionUnits,
        0,
      ),
      navUnitPrice: getDefaultValue(previousPeriodInputs?.navUnitPrice, 0),
      capOnPerformanceFee: getDefaultValue(
        previousPeriodInputs?.capOnPerformanceFee,
        0,
      ),
      periodUnderPerformance: getDefaultValue(
        previousPeriodInputs?.periodUnderPerformance,
        0,
      ),
    },
    fundInformation: {
      name: getDefaultValue(fundInformation?.name, ""),
      code: getDefaultValue(fundInformation?.code, ""),
      preparedBy: getDefaultValue(fundInformation?.preparedBy, ""),
      apir: getDefaultValue(fundInformation?.apir, ""),
    },
  };
}
